$color-grey-light: #f1f1f1;
$color-text: #333;

@import "./assets/css/variables";

body {
  background: var(--color-background) url(https://lightthedeep.com/wp-content/themes/lightthedeep/_imgs/light-the-deep-background.jpg) no-repeat left top;
  background-size: cover;
  color: var(--color-text);
  line-height: 1.2;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
p,
ol {
  font-family: 'Open Sans', sans-serif;
  margin: 1.5em 10px;
}

h1 {
  font-size: 48px;
  margin-bottom: 5px;
  & + p {
    margin-top: 0;
  }
}
h2 {
  font-size: 32px;
  margin-bottom: 10px;
  & + p {
    margin-top: 0;
  }
}
h3 {
  font-size: 22px;
  font-weight: normal;
  line-height: 1.4;
}

p {
  line-height: 1.7;
}

.smooth {
  transition: all 0.5s ease;
}

.app-wrapper {
  height: 100%;
  position: fixed;
  width: 100%;
  .branding-and-navigation {
    background: rgba(0,0,0,0.9);
    display: flex;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 40;
  }

  .sidebar,
  .app-space {
    height: 100%;
    .content {
      margin: 40px 20px;
      padding: 20px;
    }
  }

  .sidebar {
    @extend .smooth;
    left: 0;
    position: fixed;
    top: 0;
    transform: translateX(-320px);
    z-index: 20;
  }
  &.nav-open {
    .sidebar {
      transform: translateX(0);
    }
  }
  .art {
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }
  .play-stuff {
    background: rgba(0,0,0,0.8);
    box-sizing: border-box;
    display: none;
    height: 100%;
    left: 0;
    overflow: scroll;
    padding: 0 0 200px;
    position: fixed;
    top: 0;
    width: 100%;
    &.active {
      display: block;
    }
  }
  .now-playing {
    background: rgba(0,0,0,0.9);
    bottom: 0;
    box-sizing: border-box;
    left: 0;
    padding: 10px 20px;
    position: fixed;
    transition: all 0.5s ease;
    vertical-align: middle;
    width: 100%;
    .active-song {
      display: none;
      flex-wrap: wrap;
      justify-content: space-between;
      vertical-align: middle;
      .time-marker {
        display: block;
        height: 5px;
        left: 0;
        margin-top: -10px;
        position: absolute;
        width: 100%;
        .listened {
          background: orange;
          display: block;
          height: 5px;
          position: absolute;
        }
      }
      .song-details {
        align-items: center;
        display: flex;
        vertical-align: middle;
        width: 70%;
        img {
          height: 80px;
          margin-right: 20px;
          width: 80px;
        }
        h3 {
          color: #fff;
          font-weight: bold;
          @media (max-width: 768px) {
            font-size: 28px;
          }
        }
      }
      .play-controls {
        @extend .smooth;
        align-items: center;
        display: flex;
        justify-content: space-around;
        opacity: 0.1;
        width: 15%;
        @media (min-width: 768px) and (max-width: 1024px) {
          width: 30%;
        }
        @media (max-width: 768px) {
          width: 30%;
        }
        &.active {
            opacity: 1;
        }
        button {
          background: transparent;
          background-position: center;
          background-repeat: no-repeat;
          background-size: auto 100%;
          border: none;
          color: #fff;
          height: 24px;
          margin: 10px;
          overflow: hidden;
          text-indent: -9999px;
          white-space: nowrap;
          width: 24px;
          &.hidden {
            display: none;
          }
          &:hover {
            cursor: pointer;
          }
          &.next {
            background-image: url(./assets/icons/next.svg);
          }
          &.play {
            background-image: url(./assets/icons/play.svg);
          }
          &.pause {
            background-image: url(./assets/icons/pause.svg);
          }
          &.previous {
            background-image: url(./assets/icons/previous.svg);
          }
        }
      }
      &.active {
        display: flex;
      }
    }
  }
}
