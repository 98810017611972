.home {
  display: grid;
  grid-template-areas: "headline headline headline headline"
    "overview overview overview overview"
    "podcast podcast songs songs";
    @media (min-width: 768px) and (max-width: 1024px) {
      grid-template-areas: "headline headline headline headline"
        "overview overview overview overview"
        "podcast podcast songs songs";
    }
    @media (max-width: 768px) {
      grid-template-areas: "headline"
        "overview"
        "podcast"
        "songs";
    }

  h1 {
    grid-area: headline;
  }
  .quick-overview {
    grid-area: overview
  }
  .podcast-overview {
    grid-area: podcast;
  }
  .songs-overview {
    grid-area: songs;
  }
  p {
    max-width: 560px;
  }

}
