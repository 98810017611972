@import "../../assets/css/variables";

.song {

  box-sizing: border-box;
  display: inline-block;
  font-size: 32px;
  font-weight: normal;
  padding: 10px 0;
  h3 {
    border-bottom: 5px solid transparent;
    display: inline-block;
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 5px;
    width: auto;
  }
  &.active {
    h3 {
      border-color: #fff;
    }
  }
  &:hover {
    cursor: pointer;
  }
}
