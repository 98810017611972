/* This File is Auto-Generated */
:root {
	--color-gray-mid: rgba(216, 216, 216, 1);
	--color-blue-dusty: rgba(137, 170, 189, 1);
	--color-text: rgba(0, 0, 0, 1);
	--color-workspace: rgba(245, 245, 245, 1);
	--color-background: #F6FAE6;
	--color-border: rgba(226, 226, 226, 1);
	--font-size-standard: 13px;
	--font-weight-standard: 400;
	--font-line-height-standard: normal;
	--font-family-standard: "'Open Sans', sans-serif";
	--font-size-headline: 18px;
	--font-weight-headline: 400;
	--font-line-height-headline: normal;
	--font-family-headline: "Open Sans";
	--stroke-borders: rgba(207, 207, 207, 1);
	--stroke-lifted: rgba(229, 229, 229, 1);
}
