@import "../../assets/css/variables";

.home-page,
.podcast-list,
.songs-list {
  color: #fff;
  display: none;
  margin: 0 auto;
  padding: 80px;
  @media (max-width: 768px) {
    padding: 20px;
  }
}

.home-page {
  &.active {
    display: block;
  }
}

.podcast-list,
.songs-list {
  height: calc(100% - 180px);
  position: absolute;
  .podcast-overview,
  .song-overview {
    grid-area: headline;
    padding-right: 20px;
    @media (max-width: 768px) {
      border-bottom: 1px solid rgba(255,255,255,0.3);
    }
    p {
      max-width: 720px;
    }
  }
  .music {
    box-sizing: border-box;
    display: grid;
    flex-direction: column;
    grid-area: music;
    height: 100%;
    overflow: scroll;
    padding: 20px 0 120px;
    width: 100%;
    @media (min-width: 768px) and (max-width: 1024px) {
      padding: 0;
    }
    @media (max-width: 768px) {
        padding: 40px 0 120px;
    }
  }
  .description {
    grid-area: description;
    padding: 50px 40px;
    @media (min-width: 768px) and (max-width: 1024px) {
      padding-top: 0;
    }
    @media (max-width: 768px) {
      display: none;
    }
    .inner {
      border-left: 1px solid rgba(255,255,255,0.2);
      opacity: 0;
      padding-left: 40px;
      transition: all 0.5s ease;
    }
    &.available {
      .inner {
        opacity: 1;
      }
    }
  }
  &.active {
    display: grid;
    grid-template-areas:
      "headline headline headline headline"
      "music music description description"
      "music music description description";
    @media (min-width: 768px) and (max-width: 1024px) {
      grid-template-areas:
        "headline headline headline headline"
        "music music description description"
        "music music description description";
    }
    @media (max-width: 768px) {
      grid-template-areas:
        "headline"
        "music";
    }
  }
}
