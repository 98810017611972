
.branding-and-navigation {
  .menu-toggle,
  .nav-back,
  .navigation {
    display: flex;
    button {
      background-color: transparent;
      border: none;
      border-bottom: 5px solid transparent;
      color: #fff;
      display: inline-block;
      margin: 10px;
      padding: 5px 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .nav-back {
    border-right: 1px solid rgba(255,255,255,0.3);
    opacity: 0;
    overflow: hidden;
    transition: all 0.5s ease;
    white-space: nowrap;
    width: 0;
    &.open {
      opacity: 1;
      width: 80px;
    }
  }
  .nav-back,
  .navigation {
    display: none;
  }
  .navigation {
    padding-left: 20px;
    button.active {
      border-color: #fff;
    }
  }
  .menu-toggle button {
    .onState {
      display: none;
    }
    .offState: {
      display: inline;
    }
    &.active {
      .onState {
        display: inline;
      }
      .offState {
        display: none;
      }
    }
  }
  &.active {
    .nav-back,
    .navigation {
      display: flex;
    }
  }
}
