@import "../../assets/css/variables";

.nav-item {
  display: block;
  margin: 10px 0;
  width: 100%;
  a {
    border-right: 10px solid transparent;
    color: #333;
    display: block;
    padding: 10px 20px;
    text-decoration: none;
    transition: all 0.5s ease;
    &:hover {
      color: #000;
    }
    &.active {
      border-right: 10px solid #333;
    }
  }
}
